import focusable from 'ally.js/src/is/focusable';

const focusOnElement = function (element) {
  if (!focusable(element)) {
    const events = ['blur', 'focusout'];

    // add tabindex to make focusable and remove again
    element.setAttribute('tabindex', '-1');
    events.forEach((event) => {
      element.addEventListener(event, () => {
        element.removeAttribute('tabindex');
      })
    })
  }

  element.focus();
}

const links = document.getElementById('skip-links').querySelectorAll('a');

links.forEach((link) => {
  link.addEventListener('click', () => {
    const target = document.getElementById(link.href.split("#")[1]);

    if (target) {
      focusOnElement(target);
    }
  });
})