import { createFocusTrap } from 'focus-trap';

var extend = function (out) {
  out = out || {};

  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i])
      continue;

    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key))
        out[key] = arguments[i][key];
    }
  }

  return out;
};

class Navigation {
  constructor(element, options) {
    this.options = extend(Navigation.defaults, options);

    this.showSideNav = this.showSideNav.bind(this);
    this.hideSideNav = this.hideSideNav.bind(this);
    this.blockClicks = this.blockClicks.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.update = this.update.bind(this);

    this.focusTrap = createFocusTrap(this.options.navEl);

    this.startX = 0;
    this.currentX = 0;
    this.touchingSideNav = false;

    this.supportsPassive = undefined;

    this._events();
    this.addEventListeners();

    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
  }

  /**
   * Initializes events for the toggle trigger an window rezize.
   * @function
   * @private
   */
  _events() {
    this.options.hasParent.forEach(element => {

      element.addEventListener('click', (event) => {
        const target = $(event.currentTarget);
        const parent = target.closest('.has-children');

        event.preventDefault();

        if (parent.attr('aria-expanded') === 'true') {
          parent.children('ul').slideUp(100);
          parent.attr('aria-expanded', 'false');
        } else {
          parent.children('ul').slideDown(100);
          parent.attr('aria-expanded', 'true');
        }
      });
    });
  }

  // toggle button style
  toggleButton() {
    if (this.options.showButtonEl.classList.contains('is-open')) {
      this.options.showButtonEl.classList.remove('is-open');
    } else {
      this.options.showButtonEl.classList.add('is-open');
    }
  }

  // apply passive event listening if it's supported
  applyPassive() {
    const self = this;

    if (this.supportsPassive !== undefined) {
      return self.supportsPassive ? { passive: true } : false;
    }

    // feature detect
    let isSupported = false;

    try {
      document.addEventListener('test', null, {
        get passive() {
          isSupported = true;
        }
      });
    } catch (e) {
      // empty
    }
    this.supportsPassive = isSupported;

    return this.applyPassive();
  }

  addEventListeners() {
    if (this.options.showButtonEl) {
      this.options.showButtonEl.addEventListener('click', this.showSideNav);
      this.options.hideButtonEl.addEventListener('click', this.hideSideNav);
      this.options.navEl.addEventListener('click', this.hideSideNav);
    }
  }

  update() {
    if (!this.touchingSideNav) {
      return;
    }

    requestAnimationFrame(this.update);
    const translateX = Math.min(0, this.startX - this.currentX);

    this.options.navEl.style.transform = `translateX(${-(translateX)}px)`;
  }

  blockClicks(evt) {
    evt.stopPropagation();
  }

  onTransitionEnd() {
    this.options.navEl.classList.remove('is-animatable');
    this.options.navEl.removeEventListener('transitionend', this.onTransitionEnd);
  }

  showSideNav() {
    this.toggleButton();
    this.options.navEl.classList.add('is-animatable');
    this.options.navEl.classList.add('is-visible');
    this.options.navEl.addEventListener('transitionend', this.onTransitionEnd);

    this.focusTrap.activate();

    // Prevent Scrolling behind the navigation
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  }

  hideSideNav() {
    this.toggleButton();
    this.options.navEl.classList.add('is-animatable');
    this.options.navEl.classList.remove('is-visible');
    this.options.navEl.addEventListener('transitionend', this.onTransitionEnd);

    this.focusTrap.deactivate();

    // Allow scrolling and restore scroll position
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}

/**
 * Default settings for module
 */
Navigation.defaults = {
  showButtonEl: document.querySelector('[data-action="menu-show"]'),
  hideButtonEl: document.querySelector('[data-action="menu-hide"]'),
  navEl: document.querySelector('.nav'),
  hasParent: document.querySelectorAll('.nav .has-children > a span')
};

if (Navigation.defaults.navEl) {
  new Navigation()
}
