const TO_TOP_BUTTON = document.querySelector('.to-top');
const SCROLL_OFFSET = 300;

if (TO_TOP_BUTTON) {
  window.addEventListener('scroll', function () {
    var target = document.getElementsByTagName('div');
    if (window.pageYOffset > SCROLL_OFFSET) {
      TO_TOP_BUTTON.classList.add('is-visible');
    }
    else if (window.pageYOffset < SCROLL_OFFSET) {
      TO_TOP_BUTTON.classList.remove('is-visible');
    }
  }, false);
}