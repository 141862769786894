const TWO_CLICK_ELS = document.querySelectorAll('[data-twoclick]');

if (TWO_CLICK_ELS.length > 0) {
  TWO_CLICK_ELS.forEach(element => {
    const btn = element.querySelector('button');
    const iframe = element.querySelector('iframe');


    if (btn && iframe) {
      btn.addEventListener('click', () => {
        element.classList.add('two-click--active');
        iframe.src = iframe.dataset.src;
      });
    }
  });
}